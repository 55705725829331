@import "../colors.scss";

.kt-doctorPage {
    display: grid;


    .kt-body {

        .kt-profile-top {            
            display: flex;            
            align-items: center;

            .kt-avatar {
                width: 120px;
                margin-right: 15px;
            }

            h1 {
                span {
                    font-size: 1.5rem;
                }
            }
        }

    }
}