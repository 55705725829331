.kt-avatar {
    
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid lightgrey;

    &.kt-no-circle {
        border-radius: 0;
    }

    &.kt-no-border {
        border: none;
    }
    
    img {
        display: inline;
        margin: 0 auto;
        //margin-left: -35%; //centers the image
        height: 100%;
        width: auto;
    }
  
    div {
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}