.kt-stars-ctrl {
    
    .kt-stars-wrapper {
        font-size: 35px;

        color: orange;
        display: inline-block;
        margin-right: 10px;

        i:hover {
            cursor: pointer;
        }

        &.kt-readOnly {
            i:hover {
                cursor: auto;
            }
        }
    }

    .kt-rating-value {
        font-size: 25px;
    }
  
}