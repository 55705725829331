.kt-flags-select-ctrl {

    img {
        width: 32px;
        margin-right: 10px;
    }

    .MuiSelect-select {
        display: flex;
    }

}

.kt-flags-select-ctrl-item {

    img {
        width: 32px;
        margin-right: 10px;
    }

}