@import "../colors.scss";

.kt-appointmentsPage {

    .kt-user-appointments {

        >div {
            display: grid;
            gap: 10px;

            .kt-user-appointment {
                border-radius: 5px;
                border: 1px solid lightgray;
                padding: 5px;

                display: grid;
                grid-template-columns: 50px auto 105px;

                .kt-appointment-label {
                    color: $secondary-color;
                }

                .kt-user-appointment-buttons {

                    border-left: 1px solid lightgray;

                    text-align: center;

                    .kt-btn {
                        width: 90px;
                        font-size: 0.8em;
                        padding-left: 5px;

                        .fa-plus {
                            color: green;
                        }

                        .fa-trash-alt {
                            color: red;
                        }
                    }
                }

            }
        }
    }


}