.kt-loading-ctrl {

    position: fixed;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $secondary-color;

    z-index: 99999;

    font-size: 18px;

    .kt-loading-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.6;
    }

    .kt-message-wrapper {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        opacity: 1;
        z-index: 999999;

        text-align: center;

        .kt-loading-icon {
            margin-bottom: 10px;
        }
    }
}