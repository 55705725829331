@import "../colors.scss";

.kt-bookingConfirmationPage {

    .kt-appointment-row {
        padding: 10px;
        margin-top: 0;
        margin-bottom: 10px;
        border-radius: 3px;

        text-align: center;

        h2 {
            font-size: 1.4em;
            font-weight: 800;
            margin-top: 0;
            color: $secondary-color;
        }

        h3 {
            font-size: 1.2em;
        }
    }

    .kt-profile-row {
        display: grid;
        grid-template-columns: 80px auto;
        margin-top: 5px;
        margin-bottom: 20px;
    }


}