.kt-booking-calendar {

    margin-top: 5px;

    .kt-booking-calendar-navigation {
        display: grid;
        grid-template-columns: 50px auto 50px;
        font-size: 1.8em;
        color: $panel-icon-color;
        margin: 15px 0 15px 0;

        .kt-btn-enabled {
            color: $primary-color;
            cursor: pointer;
        }

        div:nth-child(2) {
            font-size: 0.7em !important;
            text-align: center;
        }

        div:nth-child(3) {
            text-align: right;
        }
    }

    .kt-booking-calendar-header {
        display: grid;
        grid-template-columns: repeat(7, minmax(30px, auto));

        >div {
            text-align: center;
            border-left: 1px solid grey;

            &:first-child {
                border-left: none;
            }

            & div:first-child {
                font-weight: bold;
            }

            & div:nth-child(2) {
                margin-top: -8px;
                font-size: 0.8em;
            }
        }

        .kt-day-slots-column {
            font-weight: normal !important;
        }
    }

    .kt-booking-calendar-time-slots {
        display: grid;
        grid-template-columns: repeat(7, minmax(30px, auto));
        
        .kt-day-slots-column {
            justify-self: stretch;                        
            font-size: 0.7em;
                                                            

            >div{                            
                margin: 0 3px 10px 3px;
                border-radius: 2px;
                background-color: lightblue;
                background-color: $primary-color;
                color: white;
                text-align: center;

                cursor: pointer;

                &:hover, &.kt-selected {
                    background-color: $secondary-color;
                }
            }
        }
    }
}