﻿//$primary-color: #0596de;
//$primary-color-light: #55acee;
//$secondary-color: #fdc400;

//$primary-color: #1F3044;
//$primary-color-light: rgb(77, 100, 126);
//$secondary-color: #FB9039;
//$secondary-color: rgb(204, 4, 194);
//$secondary-color-light: rgb(177, 104, 173);

$primary-color: #24374e;
$primary-color-light: #405064;
$secondary-color: #d862c8;
$secondary-color-light: #c790c0;

$key-color: #F9D371;

$page-background-color: rgb(245, 247, 248);

$font-color: #4b6d83;
$link-color: $primary-color;

$btn-color: $primary-color-light;

$row-hover-color: rgb(251, 201, 47);
$row-hover-color-light: rgb(238, 238, 238);
$grid-line-color: rgb(230, 230, 230);

$event-background-color: rgb(173, 229, 249);
$event-background-color-left: rgb(67, 204, 252);
$event-text-color-dark: black;
$event-text-color: rgb(73, 73, 73);

$date-picker-light-color: #c1c1c1;
$date-picker-border-color: rgb(102, 102, 102);

$dialog-body-color: rgb(245, 245, 245);

$input-border-color: rgb(200,200,200);
$input-text-color: #2a2a2a;

$panel-icon-color: #c0d3e0;

$footer-color: rgb(189, 189, 189);