.kt-license-card {
    overflow: hidden;
    position: relative;
    text-align: center;
    background-color: #fff;
    height: 590px;
    max-width: 400px;
    min-width: 330px;

    &.kt-dropshadow {
        box-shadow: 10px 10px 20px lightgray;
    }

    .generic_head_price {
        margin: 0 0 20px 0;
        background-color: #f6f6f6;

        .generic_head_content {
            margin: 0 0 50px 0;

            .head_bg {
                border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
                color: #fff;
                border-style: solid;
                border-width: 90px 1411px 23px 399px;
                position: absolute;
            }

            .head {
                padding-top: 40px;
                position: relative;
                z-index: 1;

                span {
                    font-family: "Poppins", sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 2px;
                    margin: 0;
                    padding: 0;
                    text-transform: uppercase;
                }
            }
        }

        .generic_price_tag {
            padding: 0 0 20px;

            .price {
                display: block;

                .sign {
                    display: inline-block;
                    font-family: "Poppins", sans-serif;
                    font-size: 28px;
                    font-weight: 400;
                    vertical-align: middle;
                    color: #414141;
                }

                .currency {
                    font-family: "Poppins", sans-serif;
                    font-size: 60px;
                    font-weight: 300;
                    letter-spacing: -2px;
                    line-height: 50px;
                    padding: 0;
                    vertical-align: middle;
                    color: #414141;
                }

                .month {
                    font-family: "Poppins", sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 3px;
                    vertical-align: bottom;
                    color: #414141;
                }
            }
        }

    }

    .kt-description {
        min-height: 80px;
    }

    h6 {
        font-size: 16px;
        position: relative;
        font-family: 'Poppins', sans-serif;
        margin: 0 0 15px;
        font-weight: 600;
        background: none;
        line-height: 1.30em;
    }


    .kt-price-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0 10px;

        height: 80px;

        a {
            background-color: $primary-color;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
            border-radius: 50px;
            display: inline-block;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            outline: medium none;
            padding: 12px 30px;
            text-decoration: none;
            text-transform: uppercase;
            border: 1px solid $primary-color;

            &.kt-active-license {
                color: green;
                background-color: white;
                cursor: default;
                border: none;
            }

            &.kt-no-free-license {
                background-color: white;
                cursor: default;
                border: none;
                color: #414141;
            }
        }
    }

    &.active {
        .generic_head_price {
            .generic_head_content {
                .head_bg {
                    border-color: #dc48d0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dc48d0;
                }

                .head {
                    color: white;
                }
            }
        }
    }
}