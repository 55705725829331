.kt-pizzaPage {


    .fa-volume{
        color: $secondary-color;

        position: absolute;
        top: 100px;
        right: 30px;

        font-size: 50px;
    }

    .kt-registration-form {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: end;
        overflow-y: auto;
        justify-content: center;
        width: 510px;
    }

    .kt-form-ctrl {
        margin-bottom: 10px;

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        input[type=tel],
        select {
            height: 30px;
            border-radius: 5px;
            margin-left: 10px;
            width: 300px;
            border: 1px lightgray solid;
            font-size: 14px;
        }


        label {
            text-align: left;
        }

        .kt-btn {
            width: 313px;
            height: 43px;
        }

    }

    button:disabled,
    button[disabled] {
        opacity: 0.1;
        pointer-events: none;
    }


    .kt-error-message {
        color: red;
        text-align: center;
        margin: 20px;
    }



    .kt-btn {

        margin: 0;
        background-color: $secondary-color;
        color: white;

        position: relative;
        display: block;
        border: none;

        padding: 11px 29px;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;


        text-decoration: none;

        transition: border-radius 0.1s linear;
        border-radius: 0;

        user-select: none;

        &:hover {
            border-radius: 5px !important;

            background-color: $secondary-color;

            box-shadow: 0 0 10px 2px $secondary-color !important;
        }

    }


    @media screen and (max-width: 1200px) {

        video {
            margin-top: -90px !important;
        }

        .kt-mobile-center {
            margin: 0 !important;
        }
        .kt-registration-form {
            max-width: 330px;
            margin:auto;
            align-items: center;

            .kt-form-ctrl {
                max-width: 330px;
            }
            .kt-btn {
                margin-left: 10px;
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }

}