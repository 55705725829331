.kt-pulse {
    margin: 30px auto;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fa2929;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: kt-pulse 2s infinite;
  }
  .kt-pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes kt-pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes kt-pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }



        .kt-box-row-content {
            padding: 10px;

            border-radius: 10px;
            background-color: white;
            margin: 10px;
            border: 1px solid rgb(181, 181, 181);


            select:focus, textarea:focus, input:focus {
                font-size: 13px;
            }
        


            .kt-btn {
                width: 313px;
                height: 50px;
                margin: 0;
                background-color: $secondary-color;
                color: white;
        
                position: relative;
                display: block;
                border: none;
        
                padding: 11px 29px;
                font-family: "Open Sans", sans-serif;
                font-size: 18px;
                font-weight: 600;
        
        
                text-decoration: none;
        
                transition: border-radius 0.1s linear;
                border-radius: 5px;
        
                user-select: none;
        
                &:hover {
                    border-radius: 5px !important;
        
                    background-color: $secondary-color;
        
                    box-shadow: 0 0 10px 2px $secondary-color !important;
                }
        
            }

        }
        p {
            text-align: center;
        }

       
    

    audio {
        margin: 30px 0;
    }

    .kt-country-flags {
        display: flex;
        column-gap: 20px;
        justify-content: center;
        max-width: 300px;
        row-gap: 20px;
        flex-wrap: wrap;

        div {
            width: 64px;
            height: 64px;
            overflow: hidden;
            border-radius: 50%;
            border: 3px solid transparent;
            opacity: 0.4;
            cursor: pointer;

            img {
                height: 65px;
                position: relative;
                left: -13px;
                top: -1px;
            }

            &.kt-selected {
                border-color: $secondary-color;
                transform: scale(120%);
                opacity: 1;
            }

            &:hover {
                border-color: $secondary-color;
                opacity: 1;
            }
        }


    }

    .kt-form-ctrl {
        margin-bottom: 10px;

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        input[type=tel],
        select {
            height: 30px;
            border-radius: 5px;
            margin-left: 10px;
            width: 300px;
            border: 1px lightgray solid;
            font-size: 14px;
        }


        label {
            text-align: left;
        }

    

    }

    button:disabled,
    button[disabled] {
        opacity: 0.1;
        pointer-events: none;
    }


    .kt-error-message {
        color: red;
        text-align: center;
        margin: 20px;
    }



    


    @media screen and (max-width: 1200px) {

        // video {
        //     margin-top: -90px !important;
        // }

        // .kt-mobile-center {
        //     margin: 0 !important;
        // }

        // .kt-registration-form {
        //     max-width: 330px;
        //     margin: auto;
        //     align-items: center;

        //     .kt-form-ctrl {
        //         max-width: 330px;
        //     }

        //     .kt-btn {
        //         margin-left: 10px;
        //         margin-top: 30px;
        //         margin-bottom: 30px;
        //     }
        // }
    }

