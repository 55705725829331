.kt-playgroundPage-dialog {
    label { margin: 0;}
}

.kt-playgroundPage {

    font-family: "Roboto";

    background-image: url("../../../public/images/playgrounds/duo-devices.png");

    &.kt-clonr-playgroundLandingPage {
        background-image: url("../../../public/images/login-bg.png");
        //background-image: none;
    }

    background-size: cover;
    background-repeat: repeat;

    position: fixed;
    top:0;
    bottom: 0;
    width: 100%;

    .kt-playground-container {
        margin: auto;
        position: absolute;
        left: 20%;
        right: 20%;

        top: 20px;
        bottom: 20px;

        overflow-y: auto;

        .kt-prices {
            cursor: pointer;

            img {
                transform: scale(0.95);
                transition: all .2s ease-in-out;
            }

            img:hover {
                transform: scale(1);
            }
        }



        .kt-play-button {
            position: relative;
            top: -120px;
            left: 0;

            &.kt-hide {
                opacity: 0;
            }

            i {
                font-size: 60px;

            }
        }

        .kt-video-wrapper {
            width: 300px;
            display: inline-block;

            &:hover .kt-play-button i {
                opacity: 1;
            }

            video {
                cursor: pointer;
                border: 1px solid lightgray;
                border-radius: 10px;
                width: 300px;
                height: 170px;
                margin: auto;
            }
        }


    }



    @media screen and (max-width: 1200px) {

        .kt-playground-container {
            left: 20px;
            right: 20px;
            top: 20px;
            bottom: 20px;


            .kt-prices {
                img {
                    width: 300px;
                }
            }
        }
    }

}

.kt-avatar-image {
    max-width: 200px;
    background-color: rgb(235, 238, 243);
    border-radius: 10px;
    text-align: center;
    border: 1px solid lightgray;

    &:hover,
    &.kt-selected {
        border-color: $secondary-color;
        cursor: pointer;
    }
}

div.kt-img-label {
    text-align: center !important;
    margin: auto;
    width: 100%;
    font-family: "Roboto";
}

span {
    font-family: "Roboto";
}

.kt-play-button {
    position: absolute;
    top: 50%;
    left: calc(50% - 10px);
    cursor: pointer;
    pointer-events: none;

    i {
        font-size: 40px;
        color: $secondary-color;
        opacity: 0.8;
    }
}


.kt-calendr-playgroundPage {
    background: none;

    video {
        display: flex;
        justify-self: center;
        height: 100vh;
    }
}

.kt-calendr-playgroundPage-dialog {
    align-self: center !important;
}