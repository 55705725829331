.kt-price-dialog-wrapper {

    .kt-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: gray;
        z-index: 999;
        opacity: 0.7;
        pointer-events: none;
    }

    .kt-price-dialog {
        position: fixed;
        width: 800px;
        height: 1000px;
        left: calc(50% - 400px);
        top: calc(50% - 500px);
        background-color: white;
        z-index: 9999;
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        overflow: hidden;

        h3 {
            text-align: center;
            font-size: 20px;
            color: $secondary-color;
            font-weight: 600;
        }

        .kt-dialog-header {
            display: flex;
            justify-content: space-between;
            padding: 20px 20px 10px 20px;
            font-size: 25px;

            border-bottom: 1px solid $secondary-color;

            .kt-dialog-headline {
                font-weight: 600;
            }

            .kt-dialog-close-button {
                font-weight: 800;
                cursor: pointer;

                &:hover {
                    color: $secondary-color;
                }
            }
        }

        .kt-price-ctrl {
            margin: 0 20px 20px 20px;
        }

        .kt-dialog-footer {

            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $primary-color;
            min-height: 200px;

            .kt-image-wrapper {
                display: flex;
                justify-content: space-around;
                img {
                    width: 20%;
                }
            }

            .kt-btn {
                width: 200px;
                text-align: center;
            }

        }

    }
}

@media screen and (max-width: 1200px), (max-height: 1000px){

    .kt-price-dialog-wrapper {

        .kt-price-dialog {
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            width: 100%;
            height: 100%;

            border-radius: 0;

            overflow-y: auto;

            .kt-dialog-header {
                position: fixed;
                left:0;
                right:0;
                margin: 0;
                padding: 5px 20px;
                font-size: 25px;
                background-color: white;
                z-index: 999999;
                border-bottom: 1px solid $secondary-color;
            }

            .kt-price-ctrl {
                margin: 0 20px;
                margin-top: 50px;
            }

            .kt-dialog-footer {

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $primary-color;

                min-height: 200px;
                margin-top: 50px;

                .kt-image-wrapper {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-around;
                    img {
                        max-width: 150px;
                        width: 30%;
                    }
                }

                .kt-btn {
                    margin: 20px 0;
                    width: 200px;
                    text-align: center;
                }

            }
        }
    }

}