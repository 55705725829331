$line-color: rgb(223, 223, 223);
$font-color: #252525;
$max-width: 1280px;




.kt-installation-page {
    display: flex;
    flex-direction: row-reverse;

    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: $font-color;
    background-color: white;

    //margin-top: -65px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    h1 {
        font-size: 18px;
        font-weight: 800;
    }
    

    a {
        text-decoration: none;
    }

    label {
        display: block;
        margin: 15px 0 5px 0;
        font-weight: 600;
    }


    .kt-registration-bg {
        flex: 0 0 50%;
        margin: 0;
        color: white;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        background-image: url("../../../public/images/login-bg.png");

        .kt-logo-wrapper {
            font-size: 30px;

            .kt-sub-headline {
                h1 {
                    font-size: 20px;
                    color: white !important;
                }
            }
        }

        .kt-footer {
            position: absolute;
            bottom: 0;
            opacity: 0.7;
            height: 10px;
        }
    }


    .kt-registration-form {
        min-height: 100vh;
        text-align: center;
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        justify-content: center;
    }

    .kt-form-ctrl {
        margin-bottom: 15px;
        width: 300px;

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        input[type=tel],
        select {
            height: 40px;
            width: 300px;
            border-radius: 20px;
            margin-left: 0;
        }

        div {
            margin-top: 0;
            width: 300px;
            font-size: 12px;
        }
    }

    button:disabled,
    button[disabled] {
        opacity: 0.1;
        pointer-events: none;
    }

    .kt-logo {
        display: flex;
        align-items: center;

        img {
            width: 32px;
        }

        span {
            margin-left: 5px;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .kt-error-message {
        color: red;
        text-align: center;
        margin: 20px;
    }

    .kt-margin {
        margin: 20px;
    }

    .kt-wizard-page {

        //border: 1px solid $secondary-color-light;

        //width: 90%;
        max-width: 800px;
        min-height: 600px;

        margin: auto;
        margin-bottom: 20px;


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 20px;
            text-align: center;
        }

        .fa-spinner {
            margin-top: 20px;
            font-size: 80px;

            color: #d862c8;
            --fa-secondary-color: #24374e;
        }

        a {
            font-weight: 800;
        }

        .kt-features {

            min-width: 300px;

            .kt-feature {
                position: relative;
                margin: 20px 0;

                .kt-progress-bar {
                    position: absolute;
                    width: 100%;
                    height: 10px;
                    background-color: white;
                    border-radius: 5px;
                    border: 1px solid $secondary-color;
                    margin-top: -25px;

                    .kt-progress-bar-progress {
                        position: absolute;
                        width: 50%;
                        height: 10px;
                        background-color: $secondary-color;
                        border-radius: 5px;
                    }
                }
            }
        }
    }


    .kt-button-wrapper {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin: 20px 0;
    }




    .kt-btn {

        margin: 0;
        background-color: $secondary-color;
        color: white;

        width: 313px;
        height: 43px;

        position: relative;
        display: block;
        border: none;
        padding: 11px 29px;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;


        text-decoration: none;

        transition: border-radius 0.1s linear;
        border-radius: 20px;

        user-select: none;

        &:hover {
            border-radius: 5px !important;

            background-color: $secondary-color;

            box-shadow: 0 0 10px 2px $secondary-color !important;
        }

    }

}

@media screen and (max-width: 1200px) {


    .kt-installation-page {
        flex-direction: column;
        overflow-y: auto;

        .kt-registration-bg {
            flex: 0 0 100%;

            .kt-footer {
                position: relative;
                bottom: -120px;
            }
        }

        .kt-registration-form {
            flex: none;
            margin: 10px;
        }

        .kt-desktop {
            display: none !important;
        }

        .kt-wizard-page {
            //border: 1px solid $secondary-color-light;

            width: auto;
            max-width: 100%;
            min-height: 200px;

            margin-bottom: 20px;

            .kt-form-ctrl {
                margin-bottom: 10px;
                width: 250px;

                input[type=text],
                input[type=number],
                input[type=email],
                input[type=password],
                input[type=tel],                
                select {
                    height: 40px;
                    width: 230px;
                    border-radius: 20px;
                }

                button {
                    width: 243px;
                }

                div {
                    margin-top: 0;
                    width: 230px;
                    font-size: 12px;
                }
            }
        }
    }





}



@media screen and (min-width: 1200px) {

    .kt-installation-page {
        .kt-mobile {
            display: none !important;
        }
    }

}