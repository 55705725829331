.kt-switch-ctrl {
display: flex;
align-items: center;
justify-content: center;


  .kt-switch[type="checkbox"] {
    position: relative;

    z-index: 0;
    -webkit-appearance: none;

  }

  .kt-switch[type="checkbox"]+label {
    position: relative;
    display: block;
    cursor: pointer;
    font-size: 20px;
    padding-left: 70px;
    position: relative;
    margin-top: -41px;
  }

  .kt-switch[type="checkbox"]+label:before {
    width: 60px;
    height: 30px;
    border-radius: 30px;
    border: 2px solid #ddd;
    background-color: #EEE;
    content: "";
    margin-right: 15px;
    transition: background-color 0.5s linear;
    z-index: 5;
    position: absolute;
    left: 0px;
  }

  .kt-switch[type="checkbox"]+label:after {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #fff;
    content: "";
    transition: margin 0.1s linear;
    box-shadow: 0px 0px 5px #aaa;
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 10;
  }

  .kt-switch[type="checkbox"]:checked+label:before {
    background-color: $secondary-color;
  }

  .kt-switch[type="checkbox"]:checked+label:after {
    margin: 0 0 0 30px;
  }

  @media screen and (max-width: 1200px) {

    .kt-switch[type="checkbox"] {
      margin: 0 0;
    }
  }

}