$line-color: rgb(223, 223, 223);
$font-color: #252525;
$max-width: 1280px;


@keyframes stack-slide-in {
    0% {
        margin-top: -380px;
    }

    10% {
        margin-top: -380px;
    }

    20% {
        margin-top: -275px;
    }

    30% {
        margin-top: -275px;
    }

    40% {
        margin-top: -180px;
    }

    50% {
        margin-top: -180px;
    }

    60% {
        margin-top: -80px;
    }

    70% {
        margin-top: -80px;
    }

    80% {
        margin-top: 10px;
    }

    100% {
        margin-top: 10px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        left: -300px
    }

    100% {
        left: 0;
    }
}




.kt-homePageNew {
    width: 100%;

    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: $font-color;
    background-color: white;

    .kt-info-box {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
        pointer-events: none;
        background-color: rgba(100,100, 100, 0.9);


        &>div {
            position: absolute;

            top: 10px;
            bottom: 10px;
            left: 10px;
            right: 10px;
            max-width: 800px;
            max-height: 800px;
            margin: auto;

            overflow-y: auto;
            background-color: white;
            border-radius: 20px;
            border: 3px solid $secondary-color;
            padding: 20px;
            text-align: left;

            pointer-events: visible;

            strong {
                color: $secondary-color;
            }

            .kt-btn {
                margin: 30px auto;
            }
        }


    }

    a {
        text-decoration: none;
    }

    .kt-fade-in {
        animation: fadeIn 5s;
    }

    .kt-header {
        background-color: white;
        color: $font-color;
        height: 80px;
        border: none;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $line-color;
        width: 100%;
        padding: 0;
        position: fixed;
        z-index: 100;

        user-select: none;

        .kt-header-wrapper {
            display: flex;
            justify-items: center;
            align-items: center;
            max-width: $max-width;
            width: $max-width;
            height: 80px;
            margin: 0 20px;

            .kt-logo-wrapper {
                display: flex;
                align-items: center;
                width: 200px;

                .kt-logo-text {
                    color: $font-color;
                    font-size: 30px;
                    font-weight: 600;
                    margin-left: 5px;
                }
            }


            nav {
                width: 100%;
                font-weight: 600;

                ul {
                    display: flex;
                    list-style: none;
                    justify-content: center;

                    li {
                        margin: 0 20px;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                        }

                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
            }

            .kt-right-column {
                display: flex;
                align-items: center;

                a.kt-desktop {
                    font-weight: 600;
                }

                .kt-btn {
                    font-weight: 700;
                    font-size: 14px;
                    padding: 12px 16px;

                    margin: 0 0 0 20px;
                    width: 100%;

                    span {
                        width: 100%;
                    }
                }

                .fa-bars {
                    font-size: 30px;
                }
            }

        }

        a:hover {
            color: $secondary-color;
        }
    }

    video {
        border-radius: 10px;
    }

    .kt-home-body {
        display: flex;
        justify-content: center;
        width: 100%;

        margin: 0;
        padding: 0;

        margin-top: 80px;

        color: $font-color;
        background-color: white;

        background-image: url("../../../public/images/background.svg");
        background-size: 3000px;

        .kt-btn {
            width: 280px;
            text-align: center;
            height: 30px;
            font-size: 16px;
            padding: 10px 0px;
            span {
                text-align: center;
                margin: auto;
            }
        }


        ul {
            list-style: none;
            /* Remove default bullets */
            font-size: 20px;

            li {
                img {
                    width: 16px;
                }
            }

            .kt-li-plus {
                color: $secondary-color;
                font-size: 25px;
            }
        }

        ul li::before {
            content: "\2022";
            /* Add content: \2022 is the CSS Code/unicode for a bullet */

            /* Change the color */
            font-weight: bold;
            /* If you want it to be bold */
            display: inline-block;
            /* Needed to add space between the bullet and the text */
            width: 1em;
            /* Also needed for space (tweak if needed) */
            margin-left: -1.0em;
            /* Also needed for space (tweak if needed) */
            font-size: 30px;
        }

        ul.kt-main-features {
            text-align: left;
            font-size: 14px !important;
            margin-left: -25px;
            margin-bottom: 30px;

            li::before {
                content: none;
            }

            li {
                margin-bottom: 10px;

                strong {
                    font-size: 20px !important;
                    color: $secondary-color;
                    font-weight: 900 !important;
                }

                img {
                    width: 16px;
                    margin-bottom: -1px;
                }
            }
        }

        .kt-feature-details {
            min-height: 500px;
            margin: 0;

            h1 {
                text-align: left;
                font-size: 35px;
            }

            h2 {
                margin-top: -18px;
                margin-bottom: 30px;
                font-size: 18px;
                text-align: left;
            }
        }

        h1 img {
            width: 20px;
            text-align: left !important;
        }

        .kt-box-row-wrapper {
            width: 100%;

            .anchor {
                display: block;
                position: relative;
                top: -80px;
                visibility: hidden;
            }

            .kt-box-row {
                margin: 100px 0;
                width: 100%;
                display: flex;
                justify-content: center;

                &.kt-no-margin-bottom {
                    margin-bottom: 0;
                }

                .kt-single-column {
                    margin: 10px 20px;
                    text-align: center;
                }

                .kt-more-info-wrapper {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                &.kt-box-row-dark {
                    background-color: $primary-color;
                    display: flex;
                    justify-items: center;

                    .kt-box-row-content {
                        display: flex;
                        flex-direction: column;
                        .kt-image-row {
                            display: flex;
                            justify-content: space-between;

                            img {
                                height: 30vw;
                                max-height: 200px;
                            }
                        }

                        .kt-text-button-row {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            padding: 10px;

                            span {
                                font-size: 40px;
                            }

                            a {
                                width: 350px;
                                text-align: center;
                                font-size: 60px;

                                padding: 0;
                                margin: 20px 0;

                            }
                        }
                    }
                }

                &.kt-box-row-reverse {
                    background-color: $primary-color;

                    .kt-box-row-content {
                        flex-direction: row-reverse;

                        color: white;
                    }

                    h1,
                    h2 {
                        color: white;
                    }
                }

                .kt-box-row-content {
                    width: $max-width;
                    max-width: $max-width;
                    display: flex;
                    justify-content: space-between;
                    column-gap: 40px;

                    .kt-box-text {
                        margin: 20px 0;

                        &.kt-center-items {
                            display: flex;
                            align-items: center;
                        }

                        strong {
                            font-size: 2.5rem;
                            font-weight: 800;
                        }
                    }

                    .kt-box-image {
                        margin: 20px 0;
                        text-align: center;

                        &.kt-center {
                            display: flex;
                            flex-direction: column;
                            justify-self: flex-start;
                            justify-items: center;
                            text-align: center;
                        }

                        .kt-price-btn {
                            background-color: $primary-color;
                            font-size: 30px;

                            &:hover {
                                color: $secondary-color;
                            }
                        }

                        strong {
                            font-size: 2.5rem;
                            font-weight: 800;
                        }

                        img,
                        video {
                            width: 55vw;
                            max-width: 600px;
                        }

                        video {
                            //display: none;
                            cursor: pointer;
                        }

                    }



                    &.kt-big {
                        .kt-box-image-slider {

                            text-align: center;

                            .image-gallery-description {
                                font-size: 30px;
                            }

                            .image-gallery-slide .image-gallery-image, .image-gallery-slide .image-gallery-video
                            {
                                // object-fit: fill;
                                width: 100%;
                                height: 70vh;
                                border-radius: 0;
                            }

                        }
                    }
                }

            }

            .kt-bottom-caption {
                text-align: center;
                color: white;
                background-color: $secondary-color;
                padding: 5px 0;
                margin-top: 0;
            }


            h4 {
                margin-top: 40px;
                text-align: center;
                font-size: 25px;
                font-weight: 600;
                color: $font-color;
            }


        }

    }


    .kt-footer {
        background-color: $secondary-color;
        height: 80px;
        border: none;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
        color: white;

        user-select: none;

        .kt-footer-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: $max-width;
            width: $max-width;
            height: 100%;
            margin: 0 20px;
            font-size: 14px;

            .kt-logo-wrapper {
                display: flex;
                align-items: center;


                .kt-logo-text {
                    color: white;
                    font-size: 20px;
                    font-weight: 600;
                    margin-left: 5px;
                }
            }

            .kt-footer-links {
                display: flex;

                a {
                    color: white;
                    text-decoration: none;
                    margin: 0 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

            }
        }
    }




    button,
    input[type=checkbox],
    a {
        cursor: pointer;
    }

    .kt-btn {

        margin: 0;
        background-color: $secondary-color;
        color: white;

        width: 200px;

        position: relative;
        display: flex;
        align-items: center;
        vertical-align: middle;
        align-content: center;
        border: none;
        text-align: center;

        padding: 11px 29px;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;

        text-decoration: none;

        transition: border-radius 0.1s linear;
        border-radius: 5px;
        margin-bottom: 50px;

        user-select: none;

        div {
            margin: auto;
        }

        &:hover {
            border-radius: 5px !important;
        }



        &:hover::after {
            transform: scaleX(0.3);
        }

        &.kt-btn-secondary {
            background-color: $secondary-color;
            box-shadow: 5px 5px 20px rgba(216, 58, 195, 0.4);
            color: white;

            &::after {
                background-color: white;
            }
        }

        &.kt-btn-key {
            background-color: $key-color;
            color: $primary-color;
            box-shadow: 5px 5px 20px rgba(249, 211, 113, 0.4);
        }
    }



    .kt-live-bot {
        border: 1px solid white;
        border-radius: 5px;

        height: 350px;
        color: $font-color;
        overflow: hidden;

        .kt-event-item {
            width: 400px;
            min-height: 80px;
            border-radius: 5px;
            background-color: white;
            margin: 15px;

            display: flex;

            border-left: 15px solid rgb(1, 145, 1);

            &.d1 {
                margin-top: -380px;
                animation: stack-slide-in 10s infinite;
            }

            &.kt-yellow {
                border-left-color: rgb(255, 222, 34);
            }

            .fa-star {
                color: rgb(255, 222, 34);
            }

            div {
                margin: 10px 15px;
            }

            .kt-time {
                font-weight: 600;
                font-size: 20px;
            }
        }
    }

    .kt-bg-secondary {
        background-color: $secondary-color !important;
    }

    .kt-box-features {
        h1 {
            font-size: 40px;
            font-weight: 600;
            color: $font-color;
        }
    }


    @media screen and (max-width: 1200px) {
        .kt-desktop {
            display: none !important;
        }

        h1,
        h2 {
            text-align: center;
        }

        .kt-mobile-center {
            text-align: center;

            a {
                margin: auto;
            }
        }

        .kt-live-bot {

            .kt-event-item {

                margin: 15px auto;

                width: 85%;
                font-size: 12px;

                div {
                    margin: 10px 7px;
                }
            }

        }

        .kt-header {
            .kt-header-wrapper {

                .kt-logo-wrapper {
                    width: 100%;
                }


                nav {
                    display: none;

                    &.kt-mobile-menu {
                        display: block;
                        position: fixed;
                        z-index: 200;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: $primary-color;

                        animation: slideIn 0.3s forwards;

                        ul {
                            display: block;
                            font-size: 20px;
                            padding: 0;

                            li {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid white;
                                width: 100%;
                                left: 0;
                                height: 70px;
                                margin: 0;

                                a {
                                    color: white;
                                    margin-left: 20px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

            }
        }

        .kt-home-body {

            h1 {
                font-size: 25px;
            }

            ul {
                font-size: 16px;
            }

            ul li::before {
                width: 1em;

                margin-left: -1em;

                font-size: 30px;
                line-height: 1em;
            }



            .kt-box-row-wrapper {

                .kt-box-row {

                    //margin: 50px 0;
                    margin: 0;

                    &.kt-box-row-dark {
                        flex-direction: column;

                        .kt-box-row-content {
                            display: flex;
                            flex-direction: column;

                            .kt-image-row {
                                flex-direction: column;

                                justify-content: center;

                                img {
                                    height: 30vw;
                                    max-height: 200px;
                                }
                            }

                            .kt-text-button-row {
                                flex-direction: column;

                                justify-content: space-between;
                                align-items: center;
                                padding: 10px;

                                span {
                                    font-size: 30px;
                                    text-align: center;

                                }

                                a {
                                    width: 200px;
                                    font-size: 30px;
                                    padding: 20px;
                                }
                            }
                        }
                    }



                    .kt-single-column {
                        font-size: 23px;
                    }

                    .kt-box-row-content {
                        flex-wrap: wrap;
                        justify-content: center;
                        max-width: 100%;
                        width: 100%;

                        .kt-box-text {
                            width: 100%;
                            margin: 20px;

                            strong {
                                font-size: 30px;
                            }
                        }

                        .kt-box-image {
                            //width: 50%;
                            margin: 20px;

                            img,
                            video {
                                width: 100%;
                            }


                        }


                        &.kt-big {
                            .kt-box-image {

                                width: 90%;

                                img,
                                video {
                                    width: 100%;
                                }
                            }
                        }
                    }

                }

                h4 {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: -30px;
                }

            }

        }

        .kt-footer {
            background-color: $secondary-color;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 10px 0;

            .kt-footer-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                max-width: 100%;
                width: 100%;
                margin: 0 20px;
                font-size: 13px;

                .kt-logo-wrapper {

                    .kt-logo-text {
                        font-size: 20px;
                        font-weight: 600;
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }

                .kt-footer-links {
                    display: flex;
                    flex-wrap: wrap;

                    a {
                        margin: 0 5px;
                    }
                }

            }
        }

    }

    @media screen and (min-width: 1281px) {
        .kt-fullHD {
            display: block !important;
        }
    }

    @media screen and (min-width: 901px) and (max-width: 1280px) {
        .kt-HD {
            display: block !important;
        }
    }

    @media screen and (max-width: 900px) {
        .kt-qHD {
            display: block !important;
        }
    }



    @media screen and (min-width: 1200px) {

        .kt-mobile {
            display: none !important;
        }

    }
}