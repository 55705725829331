.kt-ratingPage {

    .kt-logo-wrapper {
        text-align: center;
        img {
            max-width: 300px;
            max-height: 100px;
        }
    }

    h2 {
        font-size: 25px;
        margin-bottom: 2px;
    }

    .kt-highlight{
        color: $secondary-color;
    }

    .kt-doctor-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        //color: $secondary-color;
        column-gap: 15px;
    }

    .kt-stars-ctrl {
        text-align: center;
        margin-top: 10px;
    }

    .kt-avatar {
        text-align: center;
        margin: 15px auto 15px auto;
    }

    textarea {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        width: 100%;
        max-width: 100%;

        font-family: 'Roboto', sans-serif;

        &:focus {
            outline: 1px solid $secondary-color !important;
            //border:1px solid $primary-color;
            //box-shadow: 0 0 10px #719ECE;
        }
    }
}