.kt-popup {
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: minmax(200px, 600px);

    .kt-overlay {
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;

        background-color: black;
        opacity: 0.7;
        z-index: 999;

        pointer-events: none;
    }

    .kt-panel {
        position: fixed;
        display: grid;
        grid-template-rows: 50px auto 65px;
        margin-top: 20px;
        z-index: 9999;
        
        height: 90%;

        .kt-panel-head {
            font-size: 1.9em;
            text-align: right;
            margin-top: 10px;

            i {
                cursor: pointer;
                &:hover {
                    color: black;
                }
            }
        }
        
        .kt-panel-content {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        .kt-panel-footer {
            margin-top: 15px;
            text-align: center;
            .kt-btn {
                margin-right: 0;
            }
        }

    }
}