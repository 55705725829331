.kt-price-ctrl {

    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    user-select: none;

    .kt-click {
        cursor: pointer;
    }

    .kt-slidecontainer {

        display: grid;
        grid-template-columns: 60px auto 90px;
        align-items: center;

        &>* {
            text-align: center;
        }

        i {
            color: $secondary-color;
            font-size: 40px;
        }



        .kt-slider {
            -webkit-appearance: none;
            /* Override default CSS styles */
            appearance: none;

            /* Full-width */
            height: 10px;
            /* Specified height */
            background: #d3d3d3;
            /* Grey background */
            outline: none;
            /* Remove outline */
            opacity: 0.7;
            /* Set transparency (for mouse-over effects on hover) */
            -webkit-transition: .2s;
            /* 0.2 seconds transition on hover */
            transition: opacity .2s;
            border-radius: 5px;
        }

        /* Mouse-over effects */
        .kt-slider:hover {
            opacity: 1;
            /* Fully shown on mouse-over */
        }

        /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
        .kt-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            /* Override default look */
            appearance: none;
            width: 30px;
            /* Set a specific slider handle width */
            height: 30px;
            /* Slider handle height */
            background: $secondary-color;
            /* Green background */
            cursor: pointer;
            /* Cursor on hover */
            border-radius: 50%;
        }

        .kt-slider::-moz-range-thumb {
            width: 30px;
            /* Set a specific slider handle width */
            height: 30px;
            /* Slider handle height */
            background: $secondary-color;
            /* Green background */
            cursor: pointer;
            /* Cursor on hover */
            border-radius: 50%;

        }
    }

    .kt-price-label-value {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        span {
            color: $secondary-color;
            font-weight: 800;
            font-size: 25px;
        }

        &.kt-big {
            font-size: 25px;

            span {
                font-size: 30px;
            }
        }
    }

    .kt-margin-top {
        margin-top: 30px;
    }

    table {

        border-collapse: collapse;

        tr {
            height: 50px;
            font-size: 20px;
        }

        td:nth-child(1) {
            text-align: left;
        }

        td:nth-child(2),
        td:nth-child(3) {
            text-align: center;
            border-left: 1px solid $secondary-color-light;
        }

        th, td {
            padding: 0 5px;
        }

        tfoot td{
            font-weight: 800;
            font-size: 22px;

            .kt-individual-price {
                font-weight: 400;
                font-size: 18px;
            }
        }

    }

    @media screen and (max-width: 1200px) {
        & {
            zoom: 0.8;
            -moz-transform: scale(0.8);

            table {
                th, td {
                    font-size: 18px;
                }
            }
        }
    }
}