.kt-panel {
    display: grid;
    grid-template-columns: auto;

    background-color: white;
    border-radius: 5px;
    padding: 10px 20px 20px 20px;
    margin: 10px 10px 10px 10px;

    &.kt-panel-dark {
        background-color: $primary-color;
        padding: 10px 0;
        margin:0;
        border-radius: 0;

        .kt-btn-big {
            width: 240px;
            z-index: 20;

            margin: 10px auto 10px auto;

            i {
                margin-right: 5px;
            }
        }
    }

    &.kt-with-icon {
        grid-template-columns: 40px auto;
    }

    .kt-panel-2column-row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px,250px));
    }

    .kt-panel-icon {
        font-size: 1.4em;
        background-color: white;
        padding: 0 0 0 5px;
        border-radius: 5px 0 0 5px;
        color: $panel-icon-color;

        i {
            margin-top: 15px;
        }
    }

    .kt-avatar-columns {
        display: grid;
        grid-template-columns: 65px auto;
        grid-template-rows: 70px;
        align-items: center;

        &:hover {
            color: $secondary-color-light;
            cursor: pointer;

            .kt-avatar {
                border-color: $primary-color;
            }
        }
    }

    .kt-two-small-columns {
        display: grid;
        grid-template-columns: auto 100px;

        .kt-arrow-down {
            margin-top: -10px;
            i {
                margin-left: -5px;
                padding: 5px;
                cursor: pointer;
            }
        }
    }

    form {
        display: grid;
        row-gap: 10px;

        input {
            border: 1px solid lightgrey;
            box-sizing: border-box;
            height: 45px;
            border-radius: 5px;

            margin: 0;

            &[type=submit] {
                border: none;
            }

            &[type=radio], &[type=checkbox] {
                height: auto;
                margin-left: 5px;
            }

        }


        .kt-explanation {
            font-weight: normal;
        }

    }

}