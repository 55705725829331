@import "../colors.scss";


.kt-slow-pulse {
    animation-name: kt-slow-pulse;
    animation-duration: 7s;
    animation-delay: 5s;
    animation-iteration-count: infinite;    
}

.kt-slow-pulse:hover {
    animation: none;
}

@-webkit-keyframes kt-slow-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(225, 66, 193, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes kt-slow-pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(225, 66, 193, 0.4);
        box-shadow: 0 0 0 0 rgba(225, 66, 193, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 30px rgba(204, 44, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}




.kt-appointmentPage {

    .kt-fab {
        margin: 0px;
        top: auto;
        right: 20px;
        bottom: 20px;
        left: auto;
        position: fixed;
        width: 100px;
        height: 100px;

        img {
            width: 100%;
        }

        &:hover {
            box-shadow: 0 0 10px 5px $secondary-color;
        }
    }

    .kt-highlight {
        color: white;
        font-weight: 900;
        font-size: 24px;
    }

    .kt-body {
        background-repeat: no-repeat;
        background-size: contain;

    }

    .kt-client-logo {
        max-height: 8vh;
        max-width: 300px;
        display: flex;
        margin: 1vh auto 2vh auto;
    }

    h1 {
        font-size: 1.5rem;
    }

    .kt-profile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 20px;

        h1 {
            margin-left: 10px;
        }
    }


    .kt-btn-group {
        display: flex;
        flex-wrap: wrap;
        //margin: 20px auto 5px auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;


        .kt-btn {
            display: flex;
            align-items: center;
            justify-content: center;

            box-sizing: border-box;
            width: 260px !important;
            height: 42px !important;
            padding: 5px;
            font-size: 14px;
            text-align: center !important;
            text-decoration: none;

            span {
                margin-left: 5px;
            }

            &.kt-btn-wide {
                width: 220px;
            }

            &.kt-btn-secondary-color {
                border: none;
            }

            &.kt-btn-red {
                background-color: rgb(255, 0, 34);
                color: white;
                border: none;
                box-shadow: 3px 3px 10px grey;
            }

            &.kt-btn-green {
                background-color: rgb(1, 160, 1);
                color: white;
                border: none;
            }

            .kt-big {
                font-size: large;
            }

        }

    }

    .kt-center {
        text-align: center;
    }

    .kt-landingPage-image {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        img {
            width: 80%;
        }
    }

    .kt-landingPage-video {
        margin-bottom: 20px;
    }

    // .kt-landingPage-video {
    //     position: relative;
    //     display: flex;
    //     justify-content: center;
    //     padding-bottom: 56.25%;
    //     padding-top: 30px;
    //     height: 200px;
    //     overflow: hidden;
    //     margin-bottom: 20px;

    //     iframe, object, embed, video {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         //min-height: 100px;
    //     }
    // }

    .kt-bottom-image {
        width: 50%;
    }

    .kt-two-columns {
        display: flex;
        flex-direction: row;

        div {
            width: 50%;
        }
    }

    .kt-fixed-footer {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        bottom: 0;
        padding-left: 10px;

        background-color: $secondary-color;
        color: white;

        z-index: 99;

        a {
            text-decoration: none;
            border: 1px solid white;
            padding: 2px 7px 2px 7px;
            margin-left: 10px;
        }


    }

    .kt-appointment-text {
        color: white;
        text-align: center;
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0 5px;
    }

    .kt-text-box {
        border: 1px solid $secondary-color;
        box-shadow: 3px 3px 5px $secondary-color;
        border-radius: 5px;
        padding: 5px;
    }


    h1,
    h2 {
        text-align: center;
    }

    p {
        text-align: justify;
        margin: auto;
    }

    .kt-content {
        //width: 80%;
        font-size: 1.4em;
        line-height: 1.4;
        hyphens: auto;
    }

    .kt-margin-auto {
        margin: auto;
    }


    @media screen and (max-width: 1200px) {

        .kt-fab {
            right: 10px;
            bottom: 10px;
            width: 70px;
            height: 70px;
        }

    }

}